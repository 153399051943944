import { Component, useRef, useEffect, useCallback, useState } from "react";
import { Col, Row, Image, Button } from "react-bootstrap";
import DashboardStats from "../dashboard-stats/DashboardStats";
import NavBar from "../nav/NavBar";
import "./Support.scss";
import avatar from "../../assets/images/avatar-mock.png";
import supportImage from "../../assets/images/support-image.png";
import * as firebase from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, collectionGroup, orderBy, query } from 'firebase/firestore/lite';
import Customloader from "../CustomLoader/customerLoader";
import axios from "../../api/axios";
import { ConsoleWriter } from "istanbul-lib-report";


// const app = firebase.initializeApp({
//   apiKey: "AIzaSyDoBibzVodiv5FG5AB49KQPiaXDWwGB97s",
//   authDomain: "beatbridge-30087.firebaseapp.com",
//   projectId: "beatbridge-30087",
//   storageBucket: "beatbridge-30087.appspot.com",
//   messagingSenderId: "469833312597",
//   appId: "1:469833312597:web:c51c7da60c0bc25a95e7dc",
//   measurementId: "G-M13QR99KZ0"
// });

const app = firebase.initializeApp({
  apiKey: "AIzaSyC_89oUHRz1tbvl6_MklzhtQzAouj1qa2Y",
  authDomain: "beatbridge-71924.firebaseapp.com",
  projectId: "beatbridge-71924",
  storageBucket: "beatbridge-71924.appspot.com",
  messagingSenderId: "1033922139910",
  appId: "1:1033922139910:web:dd6c091141d9e4b7bbf087"
})


const db = getFirestore(app);

function Pointer(props) {

  const item = localStorage.getItem("selectedMessageIndex");
  var localIndex = 0
  if (item !== "undefined") {
    localIndex = JSON.parse(item)
  }

  if (props.index == localIndex) {
    return <div className="pointer"></div>
  }
  return null;
}

const Support = (props) => {
  const [selectedMessageIndex, setselectedMessageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [inputMessage, setInputMessage] = useState("");
  const [messagesArray, setMessagesArray] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState("");

  const getMessages = useCallback(async () => {


    var mycollection = collection(db, `messages`);
    var myQuery = query(mycollection, orderBy("timestamp", "desc"));
    var querySnapshot = await getDocs(myQuery);

    let dummyArr = []

    console.log(querySnapshot.size)
    for (const doc of querySnapshot.docs) {
      const fields = doc.data();
      const myCollection2 = collection(db, `messages/${doc.id}/texts`);
      const myQuery2 = query(myCollection2, orderBy("timestamp", "asc"));
      const texts = await getDocs(myQuery2);

      const textsArray = [];

      for (const msg of texts.docs) {
        const msgData = msg.data();
        const getNew = {
          content: msgData.text,
          time: "2:30 pm",
          type: msgData.type,
        };
        textsArray.push(getNew);
      }

      const myTicket = {
        name: `${fields.name}`,
        avatar: fields.image || avatar,
        ticketId: doc.id,
        messages: textsArray
      };
      console.log("New Ticket __________________", myTicket.name)
      dummyArr.push(myTicket);
    }
    console.log(" __________________")
    setTimeout(function () {
      setTickets(dummyArr);
      // console.log('DUCK', 'dummyArr', dummyArr);
    }, 2000);
    setIsLoading(false)

  }, []);

  const inputNewMesaage = (e: any) => {
    e.preventDefault();
    setInputMessage(e.target.value);
  }
  const addNewMessage = async () => {
    let privateTicketId = null
    if (selectedTicketId) {
      privateTicketId = selectedTicketId;
    } else {
      privateTicketId = tickets[0].ticketId;
    }
    const mycollection = collection(db, `messages/${privateTicketId}/texts`);
    let date = new Date().getTime().toString();
    const user = localStorage.getItem("login");
    var JSONOBJ = JSON.parse(`${user}`);
    var senderId = JSONOBJ.data.user.id;
    console.log("sender_id", senderId);
    // console.log(date)
    const newMessage = {
      profilepic: "",
      receiverId: senderId,
      userid: senderId,
      text: inputMessage,
      ticketid: "6d70d5f9-be86-48ef-bbb4-69a72b6b79ce",
      timestamp: `${date}`,
      username: "admin",
      type: "out",
    };

    const docRef = await addDoc(mycollection, newMessage);
    console.log('Added new message with ID: ', docRef.id);

    console.log("After Message ADD");
    console.log("This is Object of message", tickets[selectedMessageIndex]);
    if (tickets[selectedMessageIndex]) {
      const getNew = {
        content: inputMessage,
        time: `${changeMiliSecondsToHours(1646482980000)}`,
        type: "out"
      }
      tickets[selectedMessageIndex].messages.push(getNew);
    }
    // setMessagesArray([...messagesArray, getNew]);
    setInputMessage("");
  }

  const changeMiliSecondsToHours = (milliseconds) => {
    const date = new Date(milliseconds);
    const hours = date.getHours();
    let minutes = date.getMinutes();
    // Check if minutes is a valid number, set to 0 if not
    if (isNaN(minutes)) {
      minutes = 0;
    }
    // Convert hours from 24-hour format to 12-hour format and add "am" or "pm"
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    // Combine hours and minutes into a formatted time string
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    console.log(formattedTime); // Output: "7:25 pm"
    console.log("Time")
    return formattedTime
  }

  const mock_data_chats = [
    {
      name: "Annette Murphy",
      avatar: avatar,
      messages: [
        {
          content: "hi",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "I'm good",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Good to hear!",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "What about you?",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Where are you?",
          time: "2:30 pm",
          type: "out"
        },
        {
          content: "At home!",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "Hi, how are you?",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "I'm good",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Good to hear!",
          time: "2:30 pm",
          type: "in"
        },

      ]
    },
    {
      name: "John Doe",
      avatar: avatar,
      messages: [
        {
          content: "Hi, how are you?",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "I'm good",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Good to hear!",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "What about you?",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Where are you?",
          time: "2:30 pm",
          type: "out"
        }
      ]
    },
    {
      name: "Annette Murphy",
      avatar: avatar,
      messages: [
        {
          content: "Hi, how are you?",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "I'm good",
          time: "2:30 pm",
          type: "out"
        }, {
          content: "Good to hear!",
          time: "2:30 pm",
          type: "in"
        },
      ]
    },
    {
      name: "Annette Murphy",
      avatar: avatar,
      messages: [
        {
          content: "Hi, how are you?",
          time: "2:30 pm",
          type: "in"
        }, {
          content: "I'm good",
          time: "2:30 pm",
          type: "out"
        },
      ]
    },
    {
      name: "Juan Dela Cruz",
      avatar: avatar,
      messages: [
        {
          content: "Hi, how are you?",
          time: "2:30 pm",
          type: "in"
        },
      ]
    }
  ]

  useEffect(() => {

    setInterval(() => {
      getMessages()
    }, 5000);

  }, [])

  useEffect(() => {
    if (!isLoading && tickets.length > 0) {
      console.log("Second Use Effect", tickets[0])
      if (tickets.length > 0) {
        setSelectedTicketId(tickets[0].ticketId)
      }
    }
  }, [isLoading]);

  const openMessage = (index) => {
    localStorage.setItem("selectedMessageIndex", JSON.stringify(index));
    // console.log("Open Message", tickets[index]?.ticketId);
    setSelectedTicketId(tickets[index]?.ticketId);
    setselectedMessageIndex(index);
  }

  return (
    <div>
      <NavBar />
      <div className="support-container mb-8">
        <DashboardStats />
        <Row className="text-start">
          <Col className="welcome-container">
            <Image src={supportImage} />
            <div className="title-text-container">
              <h1>Support</h1>
            </div>
          </Col>
        </Row>
        {isLoading ? < Customloader loader={isLoading} /> :
          <div className="chat-support-container">

            <div className="people-list">
              {/* <>{console.log("On Tickets______+++++++++++++++++++++", tickets, tickets.length)}</> */}
              {
                tickets.map((chat, i) => (
                  <div className={selectedMessageIndex == i ? "message-container message-active" : "message-container"} key={i} onClick={
                    openMessage.bind(this, i)}>
                    <div className="avatar-container">
                      <img src={chat.avatar} className="avatar" />
                    </div>
                    <div className="sender-name">{chat.name}</div>
                    <div className="message-link">Most Recent Messages</div>
                    <Pointer index={i} selectedMessageIndex={selectedMessageIndex} selectedTicketId={chat.name}>  </Pointer>
                  </div>

                ))
              }
            </div>
            <div className="thread-list">
              <div  >
                <div className="chat-thread-container">
                  {
                    tickets[selectedMessageIndex]?.messages.map((message, i) => (
                      <div key={i} className={message.type == 'in' ? "incoming-message" : "my-message"}>
                        <div className="message">
                          {message.content}
                        </div>
                        <div className="time">
                          {message.time}
                        </div>
                      </div>
                    ))
                  }
                </div>

              </div>

              <div >
                <div className="chat-input-container align-self-end" >
                  <textarea placeholder="Start Typing your Message" value={inputMessage} onChange={inputNewMesaage} ></textarea>
                  <Button className="send-btn" type="submit" onClick={addNewMessage}>Send</Button>
                </div>
              </div>

            </div>
          </div>
        }
      </div>

      <div className="custom-spacer">

      </div>
    </div>
  );
}


export default Support;


function getDb(): import("firebase/firestore/lite").Firestore {
  throw new Error("Function not implemented.");
}

